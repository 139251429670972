var dCoaching = [{
        "id": "career",
        "img": "./assets/img/career.jpg",
        "tools": ["Trouver sa raison d'être professionnelle","Coaching personnel", "G.R.O.W.", ],
        "title": "de Carrière",
        "color": "success",
        "ocolor": "primary",
        "who": "Les managers, les salariés, les particuliers",
        "desc": "Que vous soyez manager, employé, vous souhaitez vous réorienter, vous subissez une rupture professionnelle ?",
        "icon": "briefcase",
        "callToAction": "Contactez-moi pour un coaching de carrière",
        "list": ["Faites ressortir le leader qui est en vous", "Gérer une rupture profesionnelle", "Vous reconvertir: votre situation professionnelle ne vous plaît plus"]
    },
    {
        "id": "team",
        "img": "./assets/img/team2.jpg",
        "title": "d'équipe",
        "color": "success",
        "ocolor": "success",
        "who": "Les organisations, les PME, les TPE, les équipes sportives",
        "tools": ["Gagner en autonomie","motivation équipe", "team building"],
        "desc": "Vous souhaitez organiser un team building, renforcer l'adage du mieux réussir ensemble, avoir des clés pour mieux gérer des conflits?",
        "icon": "users",
        "callToAction": "Contactez-moi pour un coaching d'équipe",
        "list": ["Faites ressortir l'esprit d'équipe de vos collaborateurs", "Créer de l'entraide", "Faites émerger les forces de chaque membre"]
    },
    {
        "id": "life",
        "img": "./assets/img/life2.jpg",
        "title": "de vie",
        "color": "success",
        "ocolor": "warning",
        "who": "Ceux qui recherchent du sens, mieux se connaître",
        "desc": "Vous souhaitez gagner de la confiance en vous, rechercher du sens, créer votre plan de développement personnel? Vous êtes au bon endroit.",
        "tools": ["Accords toltèques", "ikigai", "CNV"],
        "icon": "heart",
        "callToAction": "Contactez-moi pour un coaching de vie",
        "list": ["Faites ressortir vos forces", "Mettre en place des actions de progression", "Gagner de la confiance"]
    },
    {
        "id": "job",
        "img": "./assets/img/job.jpg",
        "title": "à l'emploi",
        "color": "success",
        "ocolor": "info",
        "who": "Ceux qui recherchent un emploi, ceux qui veulent changer d'emploi",
        "desc": "Une offre d'emploi vous plaît mais vous ne savez pas comment y répondre, vous allez passer un entretien et vous voulez être prêt, vous rechercher une personne pour créer votre CV.",
        "tools": ["Réalisation de CV", "Entretien d'embauche", "Questions fréquemment posées"],
        "icon": "book",
        "callToAction": "Contactez-moi pour un coaching à l'emploi",
        "list": ["Réaliser un CV percutant", "Réussir son entretien d'embauche", "Préparer son entretien d'embauche"]
    },
    {
        "id": "agile",
        "img": "./assets/img/agile.jpg",
        "title": "Agile",
        "color": "success",
        "ocolor": "secondary",
        "who": "Les organisations qui se transforment, les équipes agiles, les Scrum Master",
        "desc": "Salim est passioné de la gestion de projet agile depuis 2006. Il a commencé à faire de l'eXtreme Programming en tant que développeur puis a basculé à Scrum et est devenu Coach Agile interne dans une banque de plus de 2000 personnes. Il a cette capacité à vulgariser et présenter les concepts de façon participative.",
        "tools": ["Scrum", "Kanban", "XP", "Rétrospective", "OKR"],
        "icon": "spinner",
        "callToAction": "Contactez-moi pour un coaching agile",
        "list": ["Animation de rétrospective", "Audit de l'organisation", "Aide à la bascule du Waterfall à l'agile"]
    }
];

var gPrimaryColor = "dark";
var gSecondaryColor = "secondary";
var gCoachingType = "team";


export { dCoaching, gPrimaryColor, gSecondaryColor, gCoachingType };
