import React from 'react';
import { Parallax, Background } from 'react-parallax';

const insideStyles = {
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
};

const Home = props => {
    var bg = ""
    var classButton = "btn btn-"+props.color;
    var badgeColor = "badge bg-"+props.color;
    return ( 
                        
    <div id="home" class="bg-secondary">
        <div class="container" >
            <div class="align-items-center g-0 row" style={{ height: '500px' }}>
                <div class="text-lg-end text-center col-xl-5 col-lg-6 col-md-12" id="jumbotron-img">
                    
                </div>
                <div class="col-xl-7 col-lg-6 col-md-12 desc">
                    <div class="py-5 py-lg-0">
                        <h2 class='logo'>Bonjour, je suis <span class={badgeColor}>Salim</span></h2>
                        <p class="lead">En tant que coach professionnel certifié RNCP et fort de plusieurs années d'expérience à mon actif je vous accompagne à trouver les facteurs clés de votre réussite.</p>                        
                        <p class="text-center">
                        <ul class="list-inline">
                                <li class="list-inline-item">
                                        <a class={classButton} href="tel:+33695549502"><i class="fa fa-phone" aria-hidden="true"></i> +33 6 95 54 95 02</a> 
                                </li><li class="list-inline-item">
                        
                            <a class={classButton} href="#contact"><i class="fa fa-envelope" aria-hidden="true"></i> Écrivez-moi</a> 
                            </li></ul>
                            </p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
)} 
    
export default Home;