import React from 'react';
import './App.css';

import PostForm from './component/PostForm';  


import CardUi from './component/CardUi';
import Coaching from './component/Coaching';
import Press from './component/Press';
import Home from './component/Home';
import {dCoaching, gPrimaryColor, gSecondaryColor} from "./data/DataCoaching";
import { dContact } from './data/DataContact';
import Carte from './component/Carte';

import Header from './component/Header';

import Contact from './component/Contact';
import Testimonial from './component/Testimonial';
import { dSettings } from './data/DataSettings';

export const ThemeContext = React.createContext('unknown');

function App() {
  var bgSecondaryColor = "bg-"+gSecondaryColor;
  var bgContact = "container-fluid bg-opacity-50 "+bgSecondaryColor; 
  var bgLight = "container-fluid"; 
  return (
      <ThemeContext.Provider value={dSettings}>
    <div className="App">
      <div class="container-fluid">
          <div class="row align-items-center h-100">
              <Header />
          </div>
        </div>
      
        <div>
          <Home color={gPrimaryColor} secondaryColor={gSecondaryColor} />
        </div>


        <div className={bgLight}>
          <div id="testimonial" class="row sgCard">            
          <Testimonial color={gPrimaryColor} secondaryColor={gSecondaryColor} />
          </div>
        </div>

        <Coaching />

        <div className={bgLight}>
          <div id="contact" class="row sgCard">            
            <PostForm title={dContact.title} desc={dContact.desc} color={gPrimaryColor} background={gSecondaryColor} img={dContact.img} />
          </div>
        </div>

        <div id="footer"  class="footer">
          <div class="container">
              <div className="row">
                <div className="col-xs-12">                                    
                  <p className='p-5'>Copyright © 2022 <a href="#" class='btn'><i class="fa fa-bullseye"></i>  <span class="logo" style={{"font-size":"18px", color:'#000'}}> GO-COACH</span></a>. Tous droits r&eacute;serv&eacute;s. Design Salim GOMRI.</p>                          
               </div>                                       
              </div>
            </div>
          </div>
        </div>
        </ThemeContext.Provider>
    
  );
}

export default App;
