var dTestimonial = [
  {
    content:"J'ai travaillé avec Salim durant la transformation du département digital d'une grande banque au Luxembourg. Salim est enthousiaste, à l'écoute et utilise le feedback qui lui est donné pour s'améliorer. Son implication dans la mise en oeuvre des pratiques agiles aux sein des équipes est sans faille.",
    author: "Arnaud, Founder / Managing Director",
    type:"agile",
    img:""
  },
  {
    content:"J’ai eu la chance de rencontrer Salim lors d’un coaching. Grâce à son écoute, ses mises en situation et sa capacité à nous faire prendre conscience, Salim a su m’accompagner dans mon projet professionnel et m’apporter toutes les clés qui m’ont permis d’avancer et d’appréhender le monde du travail. Merci !",
    author: 'Léa, Marketing Product Manager',
    type:"d'emploi"

  },
    {
      content:
        "One important aspect of a digital transformation is to bring autonomy to teams having often lived only a frustrating but comfortable command and control culture. You are lucky in this situation to have Salim as Agile Coach to transform a team mindset. Salim is a servant leader who will do everything to help the team, to show them that autonomy brings motivation but also a lot of duties and he won't hesitate to put the team in front of their responsibilities during a retro concluding a very bad sprint. This magical emotional intelligence is always delivered with a great sense of humour and a lot of creativity!",
      author: "David, Business Digital Lead",
      type:"d'équipe et coaching agile",
      img:""
    },
    {
      content: "Salim a su trouver les mots pour me faire basculer d'un poste à un autre. Il a su mettre en relief mes forces et de façon très habile a su transformer ma façon d'échanger avec mes collègues. J'ai évolué et même mes proches en ont bénéficié... Encore un grand merci Salim.",
      author: "Nicolas, Directeur Informatique",
      type:"de carrière",
      img:""
    },
    {
      content:
        "C'est incroyable à quel point Salim a su me faire prendre conscience de certains points. Son enthousiasme et son humeur constante fait de Salim le coach par excellence. Bien qu'en période de remise en question professionnelle totale, Salim a su m'accompagner pour me donner les clés de la réussite. Il m'a aidé à trouver en moi la force pour faire ce que je voulais. Merci encore Salim.",
      author: "Alexandre, Nouvellement Ingénieur du son",
      type:"de carrière",
      img:""
    },
    {
      content: "I have worked with Salim over a year, as part of the same Agile transformation community. I saw him grow really fast, due to his great soft skills, passion, care for the well-being of others and understanding of the core scrum principals. I would work with him in any other company as he is a great team oriented professional and a friend. Would recommend him to anyone that needs to increase positivism, dedication and quality in their organization. As a bonus he brings his visual facilitation skills and huge library of tools for facilitation.",
      author:"Arthur, Agile Coach",
      type:"agile",
      img:""
    },
    {
      content: "Suite à cette période difficile qu'est la COVID-19, aux différentes pressions de part les parents d'élèves et toutes les restrictions, je me devais de faire un point dans ma carrière et ma vie. Salim a su m'écouter, me challenger et me rappeler mes forces. Forces que je pensais épuisées... Je te remercie pour ces échanges qui m'ont fait grandir et me rappeler à quel point j'aime mon métier.",
      author: "Sandra, Professeur des écoles",
      type:"de vie",
      img:""
    }
  ];

  var gTestimonial = {'title': 'Quelques témoignages...', 'img'   : './assets/img/testimonial.jpg', interval:10000}
  export {dTestimonial, gTestimonial};