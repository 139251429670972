import React, {useState} from "react";
import { Parallax, Background } from 'react-parallax';
import CardImgUi from "./CardImgUi";
import { useContext } from 'react';
import { ThemeContext } from '../App';

const CardTextUi = (props) => {
    const [selected, setSelected] = useState('home');
    const theme = useContext(ThemeContext);
  
    const selectedClass = 'bg-' +theme.primaryColor + ' selected';
    function changeCoachingType (event) {
        //event.preventDefault();
        theme.selectedLink = "contact";
      
        var coachingType = event.target.name;
        var $select = document.getElementById('coachingType');
        $select.value = coachingType; 
      

        var $elements = document.getElementsByClassName('selected');
        for (let i = 0; i < $elements.length; i++) {
            $elements[i].classList.remove('selected');;
        }
        document.getElementById('menuContact').classList.add('selected');
    }
  
    var classColor = "badge dark bg-"+props.secondaryColor, 
        icon = "fa fa-"+props.icon,
        badgeColor = "badge bg-"+props.color,  
        titleBadgeColor = "badge bg-"+props.secondaryColor,  
        btnColor = "btn text-white bg-"+props.color;

        return (         
            <div>
                <h3 class="subtitle">Coaching <span class={badgeColor}>{props.title}</span></h3>
                <p><span class={titleBadgeColor}>PUBLIC</span> {props.who}</p>
                <p class="card-text text-justify"><span class={titleBadgeColor}>DESCRIPTION</span> {props.desc} </p>
                <ul class="list-inline">
                <li class="list-inline-item"> <span class={titleBadgeColor}>OFFRE</span> </li>
                    {props.list.map((value, index) => {
                        var badgeColor = "badge bg-"+props.color;
                        return <li class="list-inline-item"> {value} | </li>
                    })} 
                    <li class="list-inline-item">...</li>
                </ul>
                                   
                <ul class="list-inline">
                <li class="list-inline-item"> <span class={titleBadgeColor}>OUTILS</span> </li>
                    {props.tools.map((value, index) => {
                        var badgeColor = "badge bg-secondary "+props.color;
                        return <li class="list-inline-item"> {value} | </li>
                    })} 
                    <li class="list-inline-item">...</li>
                </ul>
                <p class="text-center"><a name={props.id} onClick={changeCoachingType} id="" class={btnColor} href="#contact" role="button"><i class={icon} aria-hidden="true"></i> Contactez-moi </a></p>
            </div>
    );  
} // CardTextUi

const CardUi = props => {
    var bgColor = "row align-items-center text-left h-100 sgCard",
        title = "Coaching "+props.title,
        colImg = "col-xl-6 col-lg-6 col-md-12",
        colTxt = "col-xl-6 col-lg-6 col-md-12"
        ;
   
    if (props.index & 1 ) {
        return (
             <div class={bgColor}>
                <div class={colTxt}>    
                    <CardTextUi id={props.id} title={props.title} color={props.color} secondaryColor={props.secondaryColor}
                        desc={props.desc} icon={props.icon} callToAction={props.callToAction} list={props.list} who={props.who} tools={props.tools} />
                </div>
                <div class={colImg}>
                    <CardImgUi img={props.img} title={title} />       
                </div>
            </div>            
    ); } else {
        return (
            <div class={bgColor}>
                <div class={colImg}>
                    <CardImgUi img={props.img} title={title} />
                </div>        
                <div class={colTxt}>
                    <CardTextUi id={props.id} title={props.title} color={props.color} secondaryColor={props.secondaryColor}
                        desc={props.desc} icon={props.icon} callToAction={props.callToAction} list={props.list} who={props.who} tools={props.tools} />
                </div>
            </div>
        );
    } 
} // CardUi

export default CardUi;