import { dTestimonial, gTestimonial} from "../data/DataTestimonial";
import CardImgUi from "./CardImgUi";

const Testimonial = props => {
  var classColor = "badge dark bg-"+props.color, classSecondaryColor = "badge dark bg-"+props.secondaryColor;
  return (
    <div class="container-fluid">

    <div class="row align-items-center text-left h-100">
        
        <div className="col-xl-6 col-lg-6 col-md-12">   
            <h3 class="subtitle"><span class={classColor}> <i class="fa fa-quote-left" aria-hidden="true"></i></span> {gTestimonial.title} </h3>
            <p></p>
            <div id="testimonialCarousel" class="carousel carousel-dark slide" data-bs-ride="carousel">
              <div class="carousel-indicators">
              {dTestimonial.map((c, index) => {
                var liClass=''; var target="#carouselId";
                if (!index) { liClass='active';}
                return ( 
                  <button type="button" data-bs-target="#testimonialCarousel" data-bs-slide-to={index} class={liClass} aria-current="true" aria-label={c.title}></button>
                );
              })}
              </div>
              <div class="carousel-inner">
              {dTestimonial.map((c, index) => {
                var liClass='carousel-item'; var target="#carouselId";
                if (!index) { liClass+=' active';}
                return ( 
                  <div class={liClass} data-bs-interval={gTestimonial.interval}>                    
                    <div class="bg-gray d-block w-100 text-center" style={{ }} alt={c.author}>
                    <h5>{c.author}</h5>
                      <small><span class={classSecondaryColor}>Coaching {c.type}</span></small>
                      <p></p>
                      <p><i class="fa fa-quote-left" aria-hidden="true"></i> {c.content} </p>
              <hr style={{"background-color":"white"}}/>
                      

                    </div>
                    <div class=" d-none d-md-block">                      
                    </div>
                  </div>
                );
              })}                
              </div>
              <hr style={{"background-color":"white"}}/>
              <button class="carousel-control-prev" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Précédent</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Suivant</span>
              </button>
            </div>

    <div>
      
      
    </div>
    </div>
    <div className="col-xl-6 col-lg-6 col-md-12">
      <CardImgUi img={gTestimonial.img} />
    </div>
    </div>
    </div>
  );
}

export default Testimonial;