import React , { Component } from 'react'
import axios from 'axios'
import CardImgUi from "./CardImgUi";
import { dCoaching, gPrimaryColor, gSecondaryColor } from '../data/DataCoaching';


const ContactImg = props => {
    return (
        <div>
            <CardImgUi img={props.img} title={props.title} />
            <p className="text-center" style={{"margin-top":"30px"}}><a class="btn btn-dark" href="tel:+33695549502"> <i class="fa fa-phone" aria-hidden="true"></i> +33 6 95 54 95 02</a></p>
            <p className="text-center" style={{"margin-top":0}}><i class="fa fa-map-marker" aria-hidden="true"></i> FRANCE | LUXEMBOURG</p>
        </div>                
    );
}

class ContactForm extends Component{

    constructor(props){
        super(props)
        this.state= {
            name:'',
            email:'',
            coachingType:'',            
            message:'',
            resultMessage:'', 
            resultClass:'',
            baseClass: "alert alert-",
        }
       
    }

    handleHtmlControlChange = (event) => {
        this.setState({ [event.target.name]:event.target.value   })
    }

    handleChange = (event) => {
        this.setState({food: Array.from(event.target.selectedOptions, (item) => item.value)});
      }

    handleSubmit  = (event) => {
        console.log(this.state);
        event.preventDefault();
        var url = 'https://go-coach.fr/lib/contact.php';
        axios.post(url,this.state).        
        then( response => {
            console.log(response);
            console.log(url);
            //this.setState({resultClass:"bg-success ."})
            this.setState({resultClass:this.state.baseClass+"success"})
            this.setState({resultMessage:"Merci "+this.state.name+"! Votre message m'a été envoyé avec succès. Je vous recontacte prochainement... A très vite."})            //this.setState({resultMessage:"Merci pour votre message envoyé avec succès. "+this.state.name+' je vous répondrai dans les plus brefs délais...'})
        }).catch( error => {
            this.setState({resultClass:this.state.baseClass+"danger"})
            this.setState({resultMessage:"Désolé "+this.state.name+"! Il semblerait qu'il y ait un souci... Merci de m'envoyer un texto ou m'appeler svp. Merci de votre compréhension..."})
            //this.setState({resultMessage: error});  
            //this.setState({message:"Merci pour votre message envoyé avec succès. "+this.state.name+' je vous répondrai dans les plus brefs délais...'})
        })
    }

    render(){
        var classColor = "badge dark bg-"+this.props.color, 
        btnColor = "btn btn-"+this.props.color;
        const { name,
        email,
        coachingType,
        message,            
        resultMessage, resultClass} = this.state
        return(
            <div>

                <h3 class="subtitle">{this.props.title} <span class={classColor}> <i class="fa fa-address-book" aria-hidden="true"></i></span></h3>

                <form onSubmit={this.handleSubmit} >
                <legend>{this.props.desc}</legend>
                    <div className="form-group">
                        <label htmlFor="name">Votre nom:</label>
                        <input name="name" required type="text" className="form-control" id="name" onChange={this.handleHtmlControlChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Votre mail:</label>
                        <input type="email" required name="email" className="form-control" id="email" onChange={this.handleHtmlControlChange} />
                    </div>       

                    <div class="row">
                            <div class="col-md-12">
                                <div class="md-form mb-0">
                                    <label for="coachingType">Votre demande concerne</label>
                                    <select name="coachingType" id="coachingType"  class="form-control" onChange={this.handleHtmlControlChange}>
                                    {dCoaching.map((value, index) => {
                                        if (value.id == this.props.coachingType) {
                                            this.state.coachingType = value.id;
                                            return <option value={value.id} selected='selected' > Coaching {value.title}</option>    
                                        } else {
                                            return <option value={value.id}> Coaching {value.title}</option>
                                        }                                        
                                    })}
                                    <option value='other'> Autre demande...</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="md-form">
                                <label for="message">Expliquez votre demande...</label>
                                <textarea required type="text" id="message" name="message" rows="4" class="form-control md-textarea" placeholder="Expliquez votre demande..." value={this.state.message} onChange={this.handleHtmlControlChange} ></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="text-center text-md-left p-5">
                        <p> <button type="submit" class={btnColor}> <i class="fa fa-paper-plane" aria-hidden="true"></i> Envoyer moi un message</button> </p>
                    </div>                    
                    
                    <div class={resultClass}> {resultMessage} </div>
                </form>
            </div>
        )
    }
}

const PostForm = props => {
    
    return (
        <div class="container-fluid">
            <div class="row align-items-center text-left h-100">
                <div className="col-xl-6 col-lg-6 col-md-12">
                    <ContactForm title={props.title} desc={props.desc} color={props.color} coachingType={props.coachingType} />
                </div>
                <div class='col-xl-6 col-lg-6 col-md-12'>
                    <ContactImg img={props.img}/>
                </div>
            </div>        
        </div>
    );
}

export default PostForm;