import React, {useState} from 'react';
import { useContext } from 'react';
import { ThemeContext } from '../App';

const Header = (props) => {
  const [selected, setSelected] = useState('home');
  const theme = useContext(ThemeContext);

  const selectedClass = 'bg-' +theme.primaryColor + ' selected';
  function changeLink (event) {
    //event.preventDefault();
    var $elements = document.getElementsByClassName('selected');
    for (let i = 0; i < $elements.length; i++) {
        $elements[i].classList.remove('selected');;
    }
    
    event.target.classList.add('selected');
  }

  return (
  <div className="container-fluid">
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div class="container-fluid">
        <a className={"navbar-brand" + (selected === 'home' ? ' '+selectedClass : '')} name="home" href="#" onClick={changeLink}><i class="fa fa-bullseye"></i> GO-COACH</a>

            <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                <div class="navbar-nav">
                <ul id="menu" class="nav nav-pills">
                  <li class="nav-item">
                    <a id="menuHome" class={"nav-link" + (selected === 'home' ? ' '+selectedClass : '')} href="#home" name="home"  onClick={changeLink}>A propos</a>
                  </li>
                  <li class="nav-item">
                    <a  id="menuTestimonial" className={"nav-link" + (selected === 'testimonial' ? ' '+selectedClass : '')} onClick={changeLink} name="testimonial" href="#testimonial">Témoignages</a>
                  </li>    
                  <li class="nav-item">
                    <a id="menuCoaching" className={"nav-link" + (selected === 'coaching' ? ' '+selectedClass : '')} href="#coaching" name="coaching" onClick={changeLink}>Coaching</a>
                  </li>   
                  <li class="nav-item dropdown">
                      <a id="menuContact" class={"nav-link" + (selected === 'contact' ? ' '+selectedClass : '')} href="#contact" name="contact" onClick={changeLink}>Contact</a>
                  </li>
                </ul>
                </div>                
                <div class="d-flex my-2 my-lg-0">
                  <a class="btn btn-light btn-sm" href="#contact"> <i class="fa fa-envelope" aria-hidden="true"></i> Contactez-moi</a> &nbsp;
                  <a class="btn btn-light btn-sm" href="tel:+33695549502"> <i class="fa fa-phone" aria-hidden="true"></i> +33 6 95 54 95 02</a>
              </div>
            </div>
        </div>
    </nav>
    
</div>

) };

export default Header;
