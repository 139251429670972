import React from "react";
import { Parallax, Background } from 'react-parallax';

const CardImgUi = props => {
        return (         
            <p class="bg-image hover-zoom">
                <img class="card-img-top w-100 shadow-lg rounded-pill" src={props.img} title={props.title} alt={props.title} />                        
            </p>        
        );  
} // CardImgUi

const CardParallaxImgUi = props => {
    const insideStyles = {
        padding: 20,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)"
      };
    return (         
        <div>
            <Parallax width="auto" bgImage={props.img} blur={{ min: -30, max: 15 }} strength={1000}>
                <div style={{ height: '500px' }} />
            </Parallax>  
        </div>
    );  
} // CardImgUi


export default CardImgUi;