import CardUi from '../component/CardUi';
import { dCoaching, gPrimaryColor, gSecondaryColor } from '../data/DataCoaching';


const Coaching = props => {
    var bg = ""
    return (         
        <div id="coaching">
            {dCoaching.map((value, index) => {
              var containerClass = "container-fluid ", id = value.id+"-coaching";
              var bg = "bg-opacity-10 bg-"+gPrimaryColor;
              if (index %2 ==0 ) {containerClass += bg;}
              return <div className={containerClass}><div className="row" id={id}> 
              <CardUi index={index} left={value.left} img={value.img} title={value.title} color={gPrimaryColor} secondaryColor={gSecondaryColor}
              desc={value.desc} id ={value.id} icon={value.icon} callToAction={value.callToAction} list={value.list} who={value.who} tools={value.tools} /> </div></div>
            })}
        </div>        
    );  
} // Coaching

export default Coaching;